(function() {
'use strict';

/**
  aflToolGroupForm Directive
*/
angular.module('aerosApp').
  component('aflToolGroupForm', {
    controller: aflToolGroupFormController,
    template: '<h1>AFL TOOL FORM</h1>',
    bindings: {
      toolType  : '=',
      groupName : '='
    }
  });

aflToolGroupFormController.$inject = ['$scope'];
function aflToolGroupFormController ($scope) {
  console.log($scope.toolType);
}

}());
